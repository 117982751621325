var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-clock-time-eight-outline","title":_vm.$tc('Terms.title'),"loading":_vm.loader},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-btn',{directives:[{name:"can",rawName:"v-can:create",value:([_vm.$constants.PRIVILEGES.TERM]),expression:"[$constants.PRIVILEGES.TERM]",arg:"create"}],attrs:{"color":"cta white--text","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$tc("Terms.createTitle"))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)]},proxy:true}])}),_c('v-row',{class:_vm.terms.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$t('Loader.terms')}}):(!_vm.loader)?_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('v-select',{staticClass:"px-3 my-3",attrs:{"readonly":_vm.loadingDT,"items":_vm.term_types,"label":_vm.$tc('Terms.searchBar'),"item-value":"value","outlined":"","clearable":"","single-line":"","hide-details":"","color":_vm.getInputColor,"item-color":_vm.getInputColor},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$tc("Terms.Type." + data.item.key.toLowerCase()))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$tc("Terms.Type." + data.item.key.toLowerCase()))+" ")]}}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"customDT",attrs:{"headers":_vm.headers,"items":_vm.terms,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
            itemsPerPageText: _vm.$tc('Terms.termsPerPage'),
            pageText: `{0}-{1} ${_vm.$tc('Terms.termsByPage')} {2}`,
            'items-per-page-all-text': _vm.$tc('Terms.allTerms'),
          },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Terms.emptySearch"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Terms.loadingSearch")))])]},proxy:true},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$tc("Terms.Type." + item.type.toLowerCase()))+" ")]}},{key:`item.range`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.max_num ? item.num + "-" + item.max_num : item.num)+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))])]}},{key:`item.actions`,fn:function({ item }){return [_c('DotsMenu',{attrs:{"options":[
                item.status.name !== _vm.$constants.STATUS.DELETED &&
                  item.editable && {
                    title: _vm.$tc('Action.update'),
                    icon: 'mdi-pencil',
                    event: 'update',
                    permissions: {
                      list: [_vm.$constants.PRIVILEGES.TERM],
                      type: 'U',
                    },
                  },
                item.status.name !== _vm.$constants.STATUS.DELETED &&
                  item.editable && {
                    title: _vm.$tc('Action.delete'),
                    icon: 'mdi-delete',
                    event: 'delete',
                    permissions: {
                      list: [_vm.$constants.PRIVILEGES.TERM],
                      type: 'D',
                    },
                  },
              ]},on:{"update":function($event){return _vm.editTerm(item)},"delete":function($event){return _vm.deleteTermConfirm(item)}}}),(!item.editable)?_c('p',{staticClass:"text-center ma-0"},[_vm._v("N/A")]):_vm._e()]}}],null,true)})],1):_vm._e()],1)],1),_c('TermForm',{attrs:{"dialog":_vm.dialog,"editTerm":_vm.term},on:{"closeDialog":_vm.close}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 error white--text"},[_vm._v(_vm._s(_vm.$tc("Terms.deleteTitle")))]),_c('v-card-text',{staticClass:"py-6 text-center"},[_vm._v(" "+_vm._s(_vm.$t("Terms.deleteQuestion", { name: _vm.term.name }))+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$tc("Action.cancel")))]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.deleteTerm}},[_vm._v(_vm._s(_vm.$tc("Action.delete")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }